<template>
    <!-- 金融行业 -->
    <div>
        <el-row>
            <el-col :span="24">
                <img class="medical-bg" src="../../img/solution/financeSolution/finance_bg.png">
            </el-col>
            <el-col :span="24" class="solution-title">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">金融行业</el-col>
                <el-col :span="17" class="center-el-col"><div class="solution-line"></div></el-col>
                <el-col :span="14" class="center-el-col">
                    <p class="text-center" style="color: #666666;">某机构既要安全合规管控，又要高效协作分享，如何把握好安全与高效的平衡点成为机构进行文件管理的一大难题。客户资料、开户信息、保险保单的录音录像等都离不开系统内的团队协作和系统外的文件分享，因此，安全合规的文件协作管理平台成为该机构乃至金融行业的刚需。</p>
                </el-col>
                <el-col :span="14" class="center-el-col">
                    <div class="title-end">
                        <div class="solution-line2"></div>
                        <div>客户背景</div>
                    </div>
                </el-col>
            </el-col>
            <el-col :span="24" class="solution-pain">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">行业痛点</el-col>
                <div class="pain-cards">
                    <div class="pain-card">
                        <div><img src="../../img/solution/financeSolution/icon1.png"></div>
                        <div class="text-center">文档汇总不便</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">金融业产生的文档除了公司内部文件之外还有客户资料。柜台服务每日产生的文件数量大，由此产生的文件需在当日工作结束后将文档整理汇总至主管处，并由主管汇总到行里。繁杂的文档加之多次的流转，文档传输耗时，文档管理麻烦。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/financeSolution/icon2.png"></div>
                        <div class="text-center">数据安全难以保障</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">企业所握信息涉及社会各界，资料的安全性和保密性必须得以保障。重要资料存储于电脑和U盘之中，一旦电脑被病毒入侵或者U盘丢失，数据外泄造成重大损失。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/financeSolution/icon3.png"></div>
                        <div class="text-center">无法掌握文件动态</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">为能准确制定项目可行性报告，投融资项目的进展情况需要进行跟踪与联络。项目情况的跟踪需要随时掌握项目文件的动态，而当前的办公软件并不具备发送文件修改消息至相关人员处，因而企业协作力度不大。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/financeSolution/icon4.png"></div>
                        <div class="text-center">无法实现严格权限管理</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">文件的机密性要求金融业有严格的岗位授权管理，员工对文件的操作需严谨记录下来，以便责任追究。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/financeSolution/icon5.png"></div>
                        <div class="text-center">行业压力</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">银行面对中国银监会提出的新资本协议合规过程中，监管部门会提出对于银行文档管理、项目过程管理、风险控制和合规管理方面的多种要求，银行往往是无从下手，并需要为此花费大量的人力和物力。</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <el-col :span="4" :sm="10" :xs="12" class="medicalSolution">伊登云解决方案</el-col>
                <div class="eden-solution-plate">
                    <p class="eden-solution-title">>>轻松在线编辑，效率由我</p>
                    <p class="eden-solution-content">金融业频繁的业务往来催生大量文件，因此，每日的文件数据汇总是一则难题。针对金融业的困扰，伊登云提供了不一样的解决方式。银行柜台职员将当日数据上传至系统，主管可即时对文件进行查看、汇总。汇总完成，只需保存后提交即可，免除了许多繁琐的操作，提高了工作效率。</p>

                    <p class="eden-solution-title">>>绝佳文件管理，繁琐不再</p>
                    <p class="eden-solution-content">存储可设置多级目录，将文件分类管理，树状展示，一览可见。不管是客户资料还是公司文件，不管是行政部文件还是项目部文件，将文件存储于系统，金融业可轻松管理文件，并且文件永久保留。</p>

                    <p class="eden-solution-title">>>实时消息提示，进展了然</p>
                    <p class="eden-solution-content">不管是投融资项目进展情况跟踪与联络，还是会计科目为实现高度统一进行的频繁业务对账，文件的动态都是关键。伊登云有文件提醒功能，金融行业工作者只需设置文件提醒，便可在文件改动时获取文件动态消息。</p>

                    <p class="eden-solution-title">>>多样权限管理，防止资料外泄</p>
                    <p class="eden-solution-content">由于数据外泄会让金融行业遭受巨大损失，因此，金融行业设有严格的岗位授权管理。为加强文件安全，文件授权管理必须跟进。伊登云设置有权限管理功能，用户可以对文件进行权限设置。通过日志还可查看文件被操作的记录，操作人、操作时间、操作类项，在信息外泄、损失产生之后，能快速找出责任人。</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "financeSolution",
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
    .medical-bg {
        width: 100%;
    }

    .center-el-col {
        margin: auto;
        float: unset;
    }

    .solution-line {
        border: 1px solid #E5E5E5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .solution-line2 {
        border: 1px solid #666666;
        width: 2rem;
        margin-right: 1rem;
        margin-top: 0.7rem;
        height: 0px;
    }

    .solution-blue-line {
        width: 48px;
        height: 6px;
        background: #2974E4;
    }

    .medicalSolution {
        margin: auto;
        float: unset;
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        color: #3764D7;
        text-align: center;
        width: auto;
    }

    .solution-title {
        padding: 3rem 0px 1.5rem 0px;
    }

    .title-end {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .solution-pain {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }

    .pain-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pain-cards {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;
        width: 100%;
        max-width: 1200px;
        margin: 2rem auto;
    }

    .pain-card {
        max-width: 23rem;
        text-align: center;
        padding: 0.75rem;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        //border: 1px solid #dee2e6;
    }

    .pain-card1 {
        border-bottom: 0px;
    }

    .pain-content {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }

    .eden-solution-plate {
        margin: 2rem 0rem;
        max-width: 1200px;
        width: 100%;
        margin: auto;
        padding: 0 5px;
    }

    .eden-solution-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold
    }

    .eden-solution-content {
        color: #333333;
        font-size: 14px;
    }

    * {
        font-size: 16px
    }

    @media screen and (min-width: 1200px){
        .pain-cards .pain-card:nth-child(3n) {
            border-right: 0px;
        }
    }

    @media screen and (max-width: 1200px){
        .pain-cards {
            max-width: 800px;
        }

        .eden-solution-plate {
            max-width: 800px;
        }

        .pain-card1 {
            border-bottom: 1px solid #dee2e6;
        }

        .pain-cards .pain-card:nth-child(2n) {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(5) {
            border-bottom: 0px;
        }
    }

    @media screen and (max-width: 800px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 400px;
        }

        .pain-card {
            border-right: 0px;
        }

    }
</style>